import {
	createRouter,
	createWebHistory,createWebHashHistory
} from 'vue-router'


const routes = [{
		path: "",
		component: () => import("@/views/Index/Index.vue"),
		redirect: "/cpa1",
		children: [
			{
				path: "/cpa1",
				name: "CPA数据预览",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/cpa/main.vue"),
			},
			
			{
				path: "/totalcpa",
				name: "CPA数据总览",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/cpa/totalcpa.vue"),
			},
			
			{
				path: "/cpa2",
				name: "CPA二级代理",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/cpa/main2.vue"),
			},
			{
				path: "/cpa3",
				name: "CPA推广链接",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/cpa/main3.vue"),
			},
			
			
			{
				path: "/cps1",
				name: "CPS数据预览",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/cps/main.vue"),
			},
			{
				path: "/totalcps",
				name: "CPS数据总览",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/cps/totalcps.vue"),
			},
			{
				path: "/cps2",
				name: "CPS二级代理",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/cps/main2.vue"),
			},
			{
				path: "/generalization",
				name: "推广链接",
				icon: "SwitchButton",
				hidden: false,
				component: () => import("@/views/generalization/generalization.vue"),
			},
			

		],
	},
	{
		path: "/login",
		hidden: true,
		component: () => import("@/views/Login/Login.vue"),
	},
];

const router = createRouter({
	//history: createWebHistory(process.env.BASE_URL),
	history: createWebHashHistory(),
	routes
})


router.beforeEach((to, from, next) => {
	window.document.title = to.meta.title == undefined ? '91代理系统' : to.meta.title
	// 定义变量表示是否从后端接口中拿到token 拿到token即为true 反之为false
	//const isLogin = window.sessionStorage.adminuserinfo.Token;
	const isLogin = window.sessionStorage.adminuserinfo;
	// 如果当前页面路由路径为/login（登录页面）或者/register（注册页面）
	if (to.path == "/login") {
		next() // 则执行next()方法 跳转到下一个页面（自己设置）
	} else {
		// 如果当前页面不是上面两个页面的路径之一 则执行以下语句
		if (!isLogin) { // 进一步判断有没有获取到token 没有获取到则说明没有经过登陆操作
			next("/login")
			return;
		}
		const dengji = JSON.parse(isLogin).User.FoleyGrade;
		
		const type = JSON.parse(isLogin).User.FoleyType;
		if (dengji != 0) {
			if(type==0){
				if (to.path != '/cpa1' && to.path != '/cpa3') {
					alert('你没有权限！')
					sessionStorage.removeItem('adminuserinfo');
					next("/login")
					return
				} else {
					isLogin ? next() : next("/login")
				}
			}else{
				if (to.path != '/cps1' && to.path != '/cps3') {
					alert('你没有权限！')
					sessionStorage.removeItem('adminuserinfo');
					next("/login")
					return
				} else {
					
					isLogin ? next() : next("/login")
				}
			}
			
		} else {
			
			isLogin ? next() : next("/login")
		}
		

	}
})

export default router