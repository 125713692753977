import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
//import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as echarts from 'echarts'






const app = createApp(App)
//app.config.globalProperties.$imgurl="http://upload.shaonian.info/"
// app.config.globalProperties.$imgurl="http://192.168.31.80:8082/"
// app.config.globalProperties.$uploadurl="http://192.168.31.80:2100"

app.config.globalProperties.$imgurl = "ttps://upload.appinfo.live/"
app.config.globalProperties.$uploadurl = "https://videoupload.appinfo.ltd"




app.use(router)
//app.use(store)
app.use(ElementPlus, {
	locale: zhCn
})
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}

//createApp(App).use(router).mount('#app')
app.mount('#app')